import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="mx-auto text-center align-middle py-40 bg-[#C2FF55]">
      <header className="bg-[#C2FF55] h-screen align-middle">
        <img src={logo} className="mx-auto lg:w-1/4 md:w-1/3 sm:w-2/3 w-2/3 align-middle" alt="logo" />
        <p className='text-xl mb-20 px-4 md:w-1/2 w-full mx-auto'>
         De website is binnenkort weer beschikbaar. Bedankt voor je geduld.
        </p>
        <a
          href='mailto:info@logicm.be'
          className='text-white rounded-lg px-10 py-4 mt-20 bg-black'
        >
          Contacteer ons via mail
        </a>
      </header>
    </div>
  );
}

export default App;
